import set from 'lodash/set.js';

/**
 * Mixin for handling JSON storage tree operations.
 */
export const jsonStorageTreeMixin = {
  computed: {
    /**
     * Checks if the designer has an external source.
     * @returns {boolean} True if there is an external source, otherwise false.
     */
    isExternalSource() {
      return !!this.designer?.externalSource;
    },

    /**
     * Computed property for external storage model.
     * Gets the storage from the block or returns an empty array if not present.
     * Sets the storage in the block using lodash's set function.
     * @type {Array}
     */
    externalStorageModel: {
      /**
       * Gets the storage from the block.
       * @returns {Array} The storage array or an empty array if not present.
       */
      get() {
        return this.block?.variables || [];
      },
      /**
       * Sets the storage in the block.
       * @param {Array} value - The new storage array.
       */
      set(value) {
        set(this.block, 'variables', value);
      },
    },
  },
};
