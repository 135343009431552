<template>
  <div class="col">
    <q-dialog ref="selectDlg">
      <q-card class="full-width">
        <q-card-section class="bg-primary text-white row">
          Select variable
          <q-space/>
          <q-btn icon="close" flat round @click="$refs.selectDlg.hide();"/>
        </q-card-section>
        <q-card-section>
          <q-tree no-selection-unset node-key="id" :nodes="varsTree" v-model:selected="selectedVar"
                  @update:selected="selectVar">
            <template #default-header="{node}">
              {{node.label}}<q-icon v-if="node?.isArray" name="data_array" size="12px"/>
            </template>
          </q-tree>
        </q-card-section>
      </q-card>
    </q-dialog>

    <div class="q-card--bordered q-pa-sm">
      <div>
        {{ title }}
      </div>

      <q-banner v-if="validationError" class="text-white bg-red q-mt-xs" dense>
        {{ validationError }}
      </q-banner>

      <div class="row col">

        <div class="row">
          <q-select @update:model-value="resetValue" :options="allowedValueTypes" label="Type"
                    v-model="selectedValue.valueType" emit-value map-options class="q-mr-sm"/>

          <template v-if="['static', 'expression'].includes(selectedValueType) && !returnOptions">
            <q-select class="col" v-if="!varTypeFilter" emit-value map-options v-model="selectedValue.type"
                      :options="varTypeOptions" label="Format"/>

            <q-checkbox
                v-if="['options'].includes(varType) || hasOnlyVarType('options')"
                v-model="isLocalizable"
                :false-value="false"
                true-value="plain"
                color="positive"
                checked-icon="public"
                unchecked-icon="public_off"
                label="Translatable"
            />
          </template>
        </div>

        <q-field label="Value" stack-label class="col" v-if="selectedValueType === 'variable'">
          <template v-slot:append>
            <q-btn icon="list" flat size="sm" @click="$refs.selectDlg.show()"/>
          </template>

          <div class="text-black cursor-pointer overflow-hidden">
            {{ selectedValue?.value }}
          </div>
        </q-field>
      </div>

      <div class="col">
        <template v-if="selectedValueType === 'static'">

          <template v-if="returnOptions">
            <q-select v-model="selectedValue.value" label="Value" map-options emit-value
                      :options="returnOptions" class="col"/>
          </template>
          <template v-else-if="varType === 'bool'">
            <q-select v-model="selectedValue.value" label="Value" map-options emit-value
                      :options="globals.options.boolValues" class="col"/>
          </template>
          <template v-else-if="['int', 'float', 'string'].includes(varType)">
            <div class="row no-wrap">
              <q-input v-model="selectedValue.value" type="textarea" autogrow label="Value" class="col"/>

              <template v-if="varType === 'string'">
                <q-checkbox
                    v-model="isLocalizable"
                    :false-value="false"
                    true-value="plain"
                    color="positive"
                    checked-icon="public"
                    unchecked-icon="public_off"
                    title="Translatable"
                />

                <template v-if="isLocalizable">
                  <localization-editor
                      v-model="selectedValue.value"
                      :alias="localeAlias"
                  />
                </template>
              </template>
            </div>
          </template>

          <template v-else-if="['image', 'lottie', 'video', 'sound', 'html'].includes(varType)">
            <media-picker v-if="!designer.externalSource" v-model="selectedValue.value" :media-type="varType" :product-id="appId"
                          @closed="$emit('updated')" @changed="$emit('updated')"
                          :module-id="moduleId" label="Value" class="col"/>

            <p3-p-node-picker v-else :type="varType" v-model="selectedValue.value" label="Value" class="col" @closed="$emit('updated')" @changed="$emit('updated')"/>
          </template>

          <template v-else-if="['icon'].includes(varType)">
            <icon-selector v-model="selectedValue.value" label="Value" class="col"/>
          </template>

          <template v-else-if="['datetime'].includes(varType)">
            <date-time-constants v-model="selectedValue.value"/>
          </template>

          <template v-if="['options'].includes(varType) || hasOnlyVarType('options')">
            <options-constants v-model="selectedValue"/>
          </template>

        </template>

        <template v-if="selectedValueType === 'input'">
          <q-input v-model="selectedValue.path" label="Input path" class="col"/>
        </template>

        <template v-if="selectedValueType === 'function'">
          <function-selector v-model="selectedValue.value" :app-id="appId" :module-id="module_id"
                             :block-id="blockId" :parent-diagram-id="parentDiagramId"/>
        </template>

        <template v-if="selectedValueType === 'expression'">
          <dynamic-string
              v-model="selectedValue.expression"
              :block-id="blockId"
              :parent-diagram-id="parentDiagramId"
              :app-id="appId"
              :module-id="moduleId"
          >
            <template #actions>
              <q-checkbox
                  v-model="isLocalizable"
                  :false-value="false"
                  true-value="plain"
                  color="positive"
                  checked-icon="public"
                  unchecked-icon="public_off"
                  title="Translatable"
              />

              <localization-editor
                  v-if="isLocalizable"
                  v-model="selectedValue.expression.value"
                  :alias="localeAlias"
              />
            </template>
          </dynamic-string>
        </template>

        <template v-if="selectedValueType === 'condition'">
          <value-condition
            v-model="selectedValue.condition"
            :block-id="blockId"
            :return-options="returnOptions"
            :only-var-type="varTypeFilter"
            :parent-diagram-id="parentDiagramId"
            :app-id="appId"
            :module-id="moduleId"
            :value-type="valueType"
          />
        </template>

        <template v-if="selectedValueType === 'db'">
          <q-input type="textarea" v-model="selectedValue.value" label="DB query" class="col"/>
        </template>
      </div>

      <template v-if="selectedValueType === 'operation'">
        <value-selector-operation v-model="selectedValue" :app-id="appId" :module-id="module_id"
                                  :block-id="blockId" :parent-diagram-id="parentDiagramId"/>
      </template>

    </div>

  </div>
</template>

<script>
import {nanoid} from 'nanoid';
import {StorageNode} from "@/../../common/db/StorageNode.js"
import {DbTableField} from "@/../../common/db/DbTableField.js"
import {treeHelper} from "@/../../common/utils/treeHelper.js"
import MediaPicker from "@/components/MediaGallery/MediaPicker.vue";
import IconSelector from "@/components/IconSelector/IconSelector.vue";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import ValueSelectorOperation from "@/components/ValueSelector/ValueSelectorOperation.vue";
import DateTimeConstants from "@/components/ValueSelector/Constants/DateTimeConstants.vue";
import ValueCondition from "@/components/ValueSelector/Conditions/ValueCondition.vue";
import OptionsConstants from "@/components/ValueSelector/Constants/OptionsConstants.vue";
import FunctionSelector from "@/components/FunctionSelector/FunctionSelector.vue";
import DynamicString from "@/components/DynamicString/DynamicString.vue";
import LocalizationEditor from '@/components/Localizations/LocalizationEditor.vue';
import {designerUiComponentsList} from '@/components/DiagramDesigner/Editor/components/UI/designerUiComponentsList';
import {DiagramService} from '@/components/DiagramDesigner/Editor/services/diagramService';
import {AppModule} from '../../../../common/db/AppModule';
import P3PNodePicker from "@/pages/p3p/components/P3PNodePicker.vue";

export default {
  mixins: [renderMixins],
  emits: ['updated', 'changed', 'update:modelValue'],
  inject: {
    canvas: {
      default: false
    }
  },
  components: {
    P3PNodePicker,
    DynamicString,
    FunctionSelector,
    OptionsConstants,
    ValueCondition,
    DateTimeConstants,
    ValueSelectorOperation,
    IconSelector,
    MediaPicker,
    LocalizationEditor,
  },
  props: {
    modelValue: {
      default: ""
    },
    returnOptions: {
      type: Array,
      default: null
    },
    parentDiagramId: {
      default: null
    },
    blockId: {
      default: null
    },
    isReference: {
      default: false
    },
    appId: {
      default: false
    },
    moduleId: {
      default: false
    },
    title: {
      default: ""
    },
    onlyVarType: {
      default: false
    },
    isArray: {
      default: false
    },
    valueType: {
      default: false
    }
  },
  name: "ValueSelector",
  data: () => ({
    selectVarWindow: false,
    selectedVar: "",
    varTypeFilter: false,
    selectedValue: {
      valueType: false,
    },

    // Types conversion
    typesConversion: {"autogenerated": "string", "integer": "int", "autoincrement": "int"},

    // Types duplication
    typesDuplication: {
      "string": ["int", "float", "text"],
      "image": ["string", "file"],
      "lottie": ["string"],
      "video": ["string"],
      "sound": ["string"],
      "text": ["string", "int", "float"],
      "float": ["int"],
      "int": ["float"]
    },

    parentDiagrams: [],

    currentModule: {},

  }),
  async created() {
    // Get parent diagrams
    await this.getParentDiagrams();

    this.currentModule = await AppModule.find(this.moduleId) || {};

    // Set var type filter
    this.varTypeFilter = this.prepareVarTypeFilter(this.onlyVarType);

    // Set value type according to prop
    this.selectedValue.valueType = 'none';//this.valueType === 'setter' ? 'dynamic' : 'static';

    // Set current value
    if (this.modelValue && typeof this.modelValue === "object") this.selectedValue = this.modelValue;

    // Add watcher to save selected value to current value on change
    this.$watch('selectedValue', function (newVal) {
      const unsupportedType = !this.selectedValue?.type || (this.varTypeFilter?.length && !this.hasOnlyVarType(this.selectedValue?.type));

      if (this.selectedValue?.valueType === 'static' && unsupportedType && this.varType) {
        this.selectedValue.type = this.varType;
      }

      this.currentValue = newVal
    }, {deep: true, immediate: true})


  },
  methods: {

    /**
     * Prepare var type filter
     * @param onlyVars
     */
    prepareVarTypeFilter(onlyVars) {

      // Init var type filter
      let varTypeFilter = Array.isArray(onlyVars) ? onlyVars : (onlyVars?.split?.(",") || []);

      // Filter non type like "array"
      varTypeFilter = varTypeFilter.filter(t => !['array'].includes(t))

      // Replace types according to conversion list
      varTypeFilter = varTypeFilter.map(t => this.typesConversion[t] || t)

      // Add duplicated types
      for (const t in this.typesDuplication) {
        if (varTypeFilter.includes(t)) {
          varTypeFilter.push(...this.typesDuplication[t])
        }
      }

      // Return var type filter
      return varTypeFilter?.length ? varTypeFilter : false;
    },

    /**
     * Reset value
     */
    resetValue() {
      this.selectedValue.value = ''

      const keysForClearing = Object.keys(this.selectedValue).filter(
          (k) => !['valueType', 'value', 'type'].includes(k)
      );

      if (!keysForClearing.length) {
        return;
      }

      for (const k of keysForClearing) {
        delete this.selectedValue[k];
      }
    },

    /**
     * Select var
     * @param opt
     */
    selectVar() {
      if (!this.selectedVar.startsWith("_")) {

        // get node
        const selNode = treeHelper.traverseTree({children: this.varsTree}, (node) => {
          if (node.id === this.selectedVar) return node;
        })

        if (selNode) {

          // Set value
          this.selectedValue = Object.fromEntries(
              Object.entries(
                  Object.assign(this.selectedValue, {
                    value: selNode.id,
                    blockId: selNode.blockId,
                    nodeId: selNode.nodeId,
                    isArray: selNode.isArray,
                    isReference: this.isReference,
                    defaultValue: selNode.defaultValue,
                    descriptor: this.valueType || 'getter',
                  })
              ).filter(([k]) => k !== 'type')
          );
        }
      }
    },

    /**
     * Flattens a hierarchical list of items into a flat array of nodes.
     *
     * @param {Array} items - The list of items to flatten.
     * @param {number} block_id - The block ID to assign to each node.
     * @param {number} [parent_id=0] - The parent ID to assign to each node.
     * @returns {Array} The flattened array of nodes.
     */
    _flatBlockVariables(items, block_id, parent_id = 0) {
      const nodes = [];

      for (const item of items) {
        nodes.push({
          ...item,
          app_id: this.appId,
          module_id: this.moduleId,
          block_id,
          parent_id,
        });

        if (item?.children?.length) {
          nodes.push(...this._flatBlockVariables(item.children, block_id, item.id));
        }
      }

      return nodes;
    },

    /**
     * Retrieves the variables for a given block type and fragment ID.
     *
     * @param {string} type - The type of block (e.g., 'diagram').
     * @param {number} [fragmentId=0] - The ID of the fragment to retrieve variables for.
     * @returns {Array} The flattened array of variables.
     */
    getBlockVariables(type, fragmentId = 0) {
      if (!this.isExternalSource) {
        return [];
      }

      const diagram = this.designer?.source;

      if (type === 'diagram') {
        return this._flatBlockVariables(diagram?.variables || []);
      }

      const fragment = (diagram?.children || []).find(f => f?.id === fragmentId && f?.type === 'Fragment');

      return this._flatBlockVariables(fragment?.variables, fragmentId);
    },

    /**
     * Get storage tree
     * @returns {*}
     */
    async getStorageTree(type, path, fromId = 0, anyModule = false) {

      // Get app nodes, it is tree structure id, parent_id
      let nodes = StorageNode.query().where(
          {
            "app_id": this.appId,
            ...(!anyModule ? {"module_id": this.moduleId} : {})
          }
      );

      if (path === 'injection:') {
        const ids = this.parentDiagrams.map(d => `diagram-${d.id}`);

        nodes = await nodes.where(StorageNode.sql().in('block_id', ids))
          .where(
            StorageNode.sql().or(
              StorageNode.sql().eq('is_injectable', 1),
              StorageNode.sql().notEq('parent_id', 0)
            )
          )
          .get();
      } else {
        nodes = await nodes.where(
          StorageNode.sql().or(
            StorageNode.sql().eq('block_id', type),
            StorageNode.sql().eq('id', fromId)
          )
        ).get();
      }

      const treeStorageNodes = await StorageNode.query().where({
        app_id: this.appId,
        module_id: this.moduleId,
        block_id: 'tree-storage',
      }).get();

      const tree = async (nodes, node_id, path) => {

        // Get current node
        const node = nodes.find(node => node.id === node_id) || {id: 0, name: "", type: "object"}

        // Reset node name for root
        if (node_id === fromId) node.name = ""

        // Add node
        const newNode = {
          id: path + node.name,
          label: `${node.name}`,
          blockId: type,
          nodeId: node.id,
          isArray: node.is_array,
          //isReference: node.is_reference,
          type: node.type,
          defaultValue: node.value,
          children: []
        };

        // Filter by type if set
        if (this.checkAllowedTypes(node) || ['object', 'db-record', 'tree-storage'].includes(node.type) || (node.id === fromId && node.block_id === 'tree-storage')) {

          // Check if record is db-record - add to children table fields list
          if (node.type === 'db-record' && node.db_table) {

            // Load fields list
            for (const t of await DbTableField.query().where("table_id", node.db_table).get()) {

              // Skip not allowed types
              if (!this.checkAllowedTypes(t)) continue;

              // Add to final list
              newNode.children.push(
                  {
                    id: path + (node.name ? node.name + "." : "") + t.name,
                    label: t.name,
                    blockId: type,
                    isArray: node.is_array,
                    //isReference: node.is_reference,
                    type: t.type,
                    children: []
                  })
            }
          }/**/

          if ((node.type === 'tree-storage' && node.tree_storage_node) || (node.id === fromId && node.block_id === 'tree-storage')) {
            const parentId = (node.id === fromId && node.block_id === 'tree-storage') ? node.id : node.tree_storage_node;
            const childrenNodes = treeStorageNodes.filter((n) => n.parent_id === parentId);

            newNode.children.push({
              id: `${path}${node.name ? `${node.name}.` : ""}id`,
              label: 'id',
              blockId: type,
              isArray: 0,
              type: 'string',
              children: [],
            })

            for (const ch of childrenNodes) {
              const chNode = await tree(treeStorageNodes, ch.id, `${path}${node.name ? `${node.name}.` : ""}`)
              if (chNode) newNode.children.push(chNode)
            }
          }

          // Process children
          for (const ch of nodes?.filter(node => node.parent_id === node_id) || []) {
            // Child nodes
            const chNode = await tree(nodes, ch.id, path + (node.name ? node.name + "." : ""))
            if (chNode) newNode.children.push(chNode)
          }

          // Return list
          return newNode;
        }
      }

      // Return tree
      return [
        ...(await tree(nodes, fromId, path))?.children || [],
        ...(path.startsWith('diagram:') ? await tree(this.getBlockVariables('diagram'), fromId, path) : {})?.children || [],
        ...(path.startsWith('fragment:') ? await tree(this.getBlockVariables('fragment', type), fromId, path) : {})?.children || [],
      ]
    },

    /**
     * Check if type is allowed
     * @param node
     * @return {boolean|*}
     */
    checkAllowedTypes(node) {
      return (!this.varTypeFilter?.length || this.compareTypes(this.varTypeFilter, node.type)) &&
          (!this.isArray || (node.is_array || node.type === 'json')) // Check for arrays
    },

    /**
     * Compare types
     * @param allowedTypes
     * @param type
     */
    compareTypes(allowedTypes, type) {

      // Init array
      if (!Array.isArray(allowedTypes)) allowedTypes = [allowedTypes]

      // Check if type is allowed
      for (const at of allowedTypes) {
        if (at === type || at === this.typesConversion[type]) return true;
      }

      // Not allowed
      return false;
    },

    /**
     * Get repeaters vars
     * @param blId
     */
    async getParentVars(blId) {

      // Vars list
      const varsList = [];

      // Blocks list
      let blList = [blId];

      // Repeater components types
      const repeaterTypes = designerUiComponentsList.reduce(
          (res, group) => [...res, ...(group.children || []).map(c => c?.isRepeater ? c.type : false).filter(Boolean)], []
      );

      // Unique blocks
      const processedIds = {}

      // Check if it is top level object - get linked object
      blList.push(...(this.canvas.getParentLinkedBlocks(blId) || []));

      // GEt block parents
      for (const inBlId of [...blList]) {
        this.canvas.getNodeParentsById(inBlId)?.find(p => {
          if (p.type === 'Fragment' && p.properties?.fragmentType === 'slot') {
            blList.push(...(this.canvas.getParentLinkedBlocks(p.id) || []));
          }
        })
      }

      // Get repeaters
      for (const blockId of blList) {

        // Get parents of current block
        for (const par of this.canvas.getNodeParentsById(blockId) || []) {

          // Skip if already processed
          if (processedIds[par.id]) continue;

          // Add to processed
          processedIds[par.id] = true;

          // Source name
          const sourceName = par?.title?.toLowerCase()

          // Process products list
          if (['InAppProductsList'].includes(par.type)) {
            varsList.push(this.processProductsList(par, sourceName))
          } else

              // Process native ads
          if (['NativeAds'].includes(par.type)) {
            varsList.push(this.processNativeAds(par, sourceName))
          } else


              // Process static options
          if (par.properties?.dataSource?.valueType === 'static') {
            varsList.push(this.processStaticOptions(par, sourceName))
          } else

              // Add repeaters vars
          if (repeaterTypes.includes(par.type)) {

            // Get children
            const children = await this.getStorageTree(par.properties?.dataSource?.blockId, sourceName + ':item.', par.properties?.dataSource?.nodeId || -1);

            // Check if no children - add "value" to represent the whole object
            if (children.length === 0) children.push({id: `${sourceName}:item.value`, label: "value"})

            // Regular data type
            varsList.push({
              id: "_" + par.title + "-" + par.id,
              label: par.title,
              children: [{
                id: `${sourceName}:item`,
                label: "item",
                children:
                    [...[{
                      id: `${sourceName}:item.number`,
                      label: "Sequence number",
                    }], ...children]
              }]
            })
          } else

              // Add fragments vars
          if (par.type === 'Fragment' && varsList.find(v => v.id === "_fragment-" + par.id) === undefined) {
            varsList.push({
              id: "_fragment-" + par.id,
              label: "Parent fragment",
              children: await this.getStorageTree(par.id, 'fragment:')
            })
          }
        }
      }

      // Return vars list
      return varsList;
    },

    /**
     * Return options list
     */
    processStaticOptions(parent, sourceName) {
      return {
        id: "_" + parent.title + "-" + parent.id,
        label: parent.title,
        children: [{
          id: `${sourceName}:item`,
          label: "item",
          children: [
            {
              id: `${sourceName}:item.title`,
              label: "title",
            },
            {
              id: `${sourceName}:item.value`,
              label: "value",
            }
          ]
        }]
      }
    },

    /**
     * Return products list
     */
    processProductsList(parent, sourceName) {
      return {
        id: "_" + parent.title + "-" + parent.id,
        label: "Products list",
        children: [{
          id: `${sourceName}:item`,
          label: "item",
          children: [
            {
              id: `${sourceName}:item.title`,
              label: "Product title",
            },
            {
              id: `${sourceName}:item.id`,
              label: "Product id",
            },
            {
              id: `${sourceName}:item.priceTitle`,
              label: "Price title",
            },
            {
              id: `${sourceName}:item.priceValue`,
              label: "Price value",
            },
            {
              id: `${sourceName}:item.currency`,
              label: "Price currency",
            },
            {
              id: `${sourceName}:item.billingPeriodTitle`,
              label: "Billing period",
            },
            {
              id: `${sourceName}:item.trialPeriodTitle`,
              label: "Trial period",
            },
            {
              id: `${sourceName}:item.trialDescription`,
              label: "Trial description",
            },
            {
              id: `${sourceName}:item.subscriptionRules`,
              label: "Subscription rules",
            },
          ]
        }]
      }
    },

    /**
     * Return native ads
     */
    processNativeAds(parent, sourceName) {
      return {
        id: "_" + parent.title + "-" + parent.id,
        label: "Native ads",
        children: [
          {
            id: `${sourceName}:id`,
            label: "Native ad id",
          },
          {
            id: `${sourceName}:title`,
            label: "Native ad title",
          },
          {
            id: `${sourceName}:description`,
            label: "Native ad description",
          },
          {
            id: `${sourceName}:image`,
            label: "Native ad image",
          },
          {
            id: `${sourceName}:cta`,
            label: "Native ad cta",
          },
          {
            id: `${sourceName}:click_url`,
            label: "Native ad click url",
          },
        ]
      }
    },

    /**
     * Check if only var type
     * @param type
     * @return {*}
     */
    hasOnlyVarType(type) {
      const types = Array.isArray(this.varTypeFilter) ? this.varTypeFilter : [this.varTypeFilter]
      return types.includes(type)
    },

    /**
     * Fetches the list of parent diagrams for the current module and parent diagram.
     * Updates the `parentDiagrams` data property with the fetched list.
     * Logs an error message to the console if the fetch operation fails.
     */
    async getParentDiagrams() {
      try {
        this.parentDiagrams = await DiagramService.getDiagramInvocationList(this.moduleId, this.parentDiagramId);
      } catch (e) {
        console.error('Error getting parent diagrams', e);
      }
    },
  },
  computed: {
    /**
     * Return var type options
     * @return {{label: string, value: string}}
     */
    varTypeOptions() {
      return [...this.globals.options.data_types, ...[{value: "options", label: "Options"}]];
    },

    /**
     * Get parent fragment id
     */
    parentFragmentId() {
      return this.canvas.getNodeParentsById(this.blockId)?.find(p => p.type === 'Fragment')?.id
    },

    /**
     * Return allowed value types
     * @return {*}
     */
    allowedValueTypes() {
      return this.globals.options.valueTypes.filter(v => !v.type || !this.valueType || v.type === this.valueType);
    },

    /**
     * Current property link
     */
    currentValue: {
      get: function () {
        return this.selectedValue || ""
      },
      set: async function (newVal) {
        this.$emit('changed', newVal)
        this.$emit('update:modelValue', newVal)
      }
    },

    /**
     * Return all storage nodes
     * @return {*}
     */
    varsTree() {
      // Load app storage nodes
      return this.wait("varsTree", async () => {

        // Result vars tree
        const vTree = [];

        // Get app vars
        vTree.push({
          id: "_app-storage",
          label: "App storage",
          children: await this.getStorageTree('app-storage', "app:")
        });

        // Get project constants
        vTree.push({
          id: "_constants",
          label: "Constants",
          children: await this.getStorageTree('constants', "constant:", 0, true)
        });

        // Diagram vars
        vTree.push({
          id: "_diagram-storage",
          label: "Diagram storage",
          children: this.parentDiagramId ? await this.getStorageTree('diagram-' + this.parentDiagramId, "diagram:") : false
        });

        if (this.allowInjectableVars) {
          // Diagram vars
          vTree.push({
            id: "_diagram-injection",
            label: "Injection",
            children: this.parentDiagramId ? await this.getStorageTree('diagram-' + this.parentDiagramId, "injection:") : false
          });
        }

        // Repeater vars
        vTree.push(...await this.getParentVars(this.blockId))

        // Return vars list
        return vTree.filter(e => e.children.length > 0)
      }, []);
    },

    /**
     * Get value type
     */
    selectedValueType() {
      return this.selectedValue.valueType || 'none'
    },

    /**
     * Computed property to fetch the storage node based on the current value's nodeId.
     * Uses the `wait` method to handle asynchronous fetching.
     *
     * @returns {Object} The storage node object.
     */
    storageNode() {
      return this.wait('storageNode', async () => {
        return await StorageNode.find(this.currentValue?.nodeId);
      }, {});
    },

    /**
     * Get var type
     */
    varType() {
      return this.storageNode.type || this.varTypeFilter[0] || this.selectedValue.type;
    },

    /**
     * A computed property that gets and sets the 'isLocalizable' property of the selected value.
     * The 'isLocalizable' property determines whether the selected value is localizable or not.
     *
     * @property {Boolean} isLocalizable - The 'isLocalizable' property of the selected value.
     * @property {Function} get - The getter method for the 'isLocalizable' property. It checks if the 'isLocalizable' property exists in the 'expression' property of the selected value. If it does not exist, it checks if the 'isLocalizable' property exists in the selected value itself. If it still does not exist, it defaults to 'false'.
     * @property {Function} set - The setter method for the 'isLocalizable' property. It sets the 'isLocalizable' property in the 'expression' property of the selected value if it exists. If it does not exist, it sets the 'isLocalizable' property in the selected value itself.
     */
    isLocalizable: {
      get() {
        return this.selectedValue?.expression?.isLocalizable || this.selectedValue?.isLocalizable || false
      },
      set(value) {
        if (this.selectedValue?.expression) {
          this.selectedValue.expression.isLocalizable = value;
        } else {
          this.selectedValue.isLocalizable = value;
        }
      }
    },

    /**
     * A computed property that gets and sets the 'localeAlias' property of the selected value.
     * The 'localeAlias' property is used to identify the localization of the selected value.
     *
     * @property {String} localeAlias - The 'localeAlias' property of the selected value.
     * @property {Function} get - The getter method for the 'localeAlias' property. It checks if the 'localeAlias' property exists in the 'expression' property of the selected value. If it does not exist, it checks if the 'localeAlias' property exists in the selected value itself. If it still does not exist, it defaults to 'false'.
     * @property {Function} set - The setter method for the 'localeAlias' property. It sets the 'localeAlias' property in the 'expression' property of the selected value if it exists. If it does not exist, it sets the 'localeAlias' property in the selected value itself.
     */
    localeAlias: {
      get() {
        return this.selectedValue?.expression?.localeAlias || this.selectedValue?.localeAlias || null;
      },
      set(value) {
        if (this.selectedValue?.expression) {
          this.selectedValue.expression.localeAlias = value;
        } else {
          this.selectedValue.localeAlias = value;
        }
      }
    },

    /**
     * Validates if the entered value matches the expected type.
     *
     * @returns {string|boolean} - Returns a string with the error message if the value does not match the type, otherwise false.
     */
    validationError() {
      if (!this.varType) {
        return false;
      }

      if (this.valueType === 'setter' && this.storageNode?.is_argument && !this.storageNode?.is_reference) {
        return 'You cannot assign a value to a non-reference argument';
      }

      // Check if the selected value is a variable
      if (this.varTypeFilter?.length && !this.varTypeFilter.includes(this.varType)) {
        return `The selected value type (${this.varType}) does not match the allowed types (${this.varTypeFilter.join(', ')}).`;
      } else if (this.selectedValueType === 'variable') {
        const node = this.storageNode;

        if (!node?.type || node.type === this.varType || (this.typesDuplication[node.type] || []).includes(this.varType)) {
          return false;
        }

        const objectTypes = ['object', 'db-record'];

        if (objectTypes.includes(node.type) && objectTypes.includes(this.varType)) {
          return false;
        }

        return `The type of the selected variable (${node.type}) does not match the type of the target value (${this.varType}).`;
      }

      const enteredValue = this.getValue(this.currentValue);

      if (!enteredValue) {
        return false;
      }

      // Check if the entered value matches the expected type
      switch (this.varType) {
        case 'int':

          if (!/^[+-]?\d+$/.test(enteredValue)) {
            return 'The entered value is not an integer.';
          }

          break;
        case 'float':

          if (!/^[+-]?\d+(\.\d+)?$/.test(enteredValue)) {
            return 'The entered value is not a float.';
          }

          break;
        case 'bool':

          if ((typeof enteredValue === 'string' && !['true', 'false'].includes(enteredValue.toLowerCase())) || typeof !!enteredValue !== 'boolean') {
            return 'The entered value is not a boolean.';
          }

          break;
        case "object":
        case "db-record":

          if (typeof enteredValue !== 'object') {
            return 'The entered value is not an object.';
          }

          break;
      }

      return false;
    },

    /**
     * Checks if the current module is a frontend module.
     *
     * @returns {boolean} True if the current module is of type 'web' or 'mobile', otherwise false.
     */
    isFrontendModule() {
      return ['web', 'mobile'].includes(this.currentModule?.type);
    },

    /**
     * Determines if injectable variables are allowed.
     * Injectable variables are allowed if there are parent diagrams,
     * the value type is not 'setter', and the current module is a frontend module.
     *
     * @returns {boolean} True if injectable variables are allowed, otherwise false.
     */
    allowInjectableVars() {
      return this.parentDiagrams.length && this.valueType !== 'setter' && this.isFrontendModule;
    },
  },

  watch: {
    /**
     * Watcher for the `isLocalizable` property.
     * If `isLocalizable` is true and `localeAlias` does not exist in the `selectedValue` object,
     * it generates a new `localeAlias` using the `nanoid` function and assigns it to `selectedValue`.
     *
     * @name isLocalizable
     * @watch
     */
    isLocalizable() {
      if (!this.isLocalizable) {
        return;
      }

      if (this.isLocalizable && !this.localeAlias) {
        this.localeAlias = nanoid(10);
      }
    },
  },
}

</script>
