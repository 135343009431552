<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h6">
      In app purchase settings
    </div>
    <q-form @submit="saveProperties">

      <div class="q-ma-sm">

        <div class="q-mb-md row text-h6 items-center">
          Products list:
          <q-space/>
          <q-btn label="Add product" color="primary" class="q-mt-md" @click="addProduct(k)" icon="add"/>
        </div>

        <q-banner v-if="!products.length" class="bg-grey-3 q-ma-sm">
          No products
        </q-banner>

        <q-card v-for="(product, k) of products" :key="k">
          <q-card-section class="row q-gutter-sm">
            <q-input label="Product id" v-model="product.productId"/>
            <q-input label="Product group" v-model="product.group"/>
            <q-select class="col-1" map-options emit-value label="Product type" v-model="product.type" :options="globals.options.iapTypes"/>
            <q-checkbox v-if="product.type === 'product'" class="bg-grey-2 q-pr-md" label="Is consumable" v-model="product.isConsumable" :true-value="1" :false-value="0"/>
            <q-input v-if="isWeb && product.type !== 'product'" label="Trial period days" v-model.number="product.trialPeriodDays" class="col"/>
            <q-input label="Product name" v-model="product.title" class="col"/>

            <q-input class="col-1" label="Google id" v-model="product.googleId"/>
            <q-input class="col-1" label="Apple id" v-model="product.appleId"/>
            <q-input class="col-1" label="Stripe ID (stage)" v-model="product.stripeIdStage"/>
            <q-input class="col-1" label="Stripe ID (release)" v-model="product.stripeIdRelease"/>

            <q-btn v-if="product.type === 'subscription'" label="Delete" flat @click="deleteProduct(k)"/>
          </q-card-section>
        </q-card>

      </div>

      <q-separator/>
      <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
    </q-form>

  </q-page>
</template>

<script>

import {AppModule} from '../../../../../../common/db/AppModule';
import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";

export default {
  mixins: [integrationsMixin],
  name: "InAppPurchaseIntegrationSettings",
  data: () => ({
    product: {},
  }),

  methods: {

    /**
     * Delete product by number
     */
    deleteProduct(num) {

      this.$q.dialog({
        title: 'Delete product',
        message: 'Are you sure you want to delete this product?',
        cancel: true,
        persistent: true
      }).onOk(() => {

        // Remove product from an array by number
        this.properties.products.splice(num, 1)
      })
    },


    /**
     * Add new product to list
     */
    addProduct() {

      // Init products
      if (!this.properties.products) this.properties.products = [];

      // Add new
      this.properties.products.push({
        name: ""
      })
    },
  },

  computed: {

    /**
     * Return integration name
     */
    integration: () => ({name: "in-app-purchase", type: "payments"}),

    /**
     * Return products
     */
    products() {
      return this.properties.products || []
    },

    /**
     * Current module
     * @return {*}
     */
    currentModule() {
      return this.wait("currentModule", AppModule.find(this.module_id), {})
    },

    /**
     * Check if the current module is of type 'web'
     * @returns {boolean} True if the current module type is 'web', otherwise false
     */
    isWeb() {
      return this.currentModule?.type === 'web';
    },
  }
}

</script>
