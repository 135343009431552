<template>
  <q-splitter
    v-if="isReady"
    class="col storage-tree"
    horizontal
    v-model="splitterModel"
  >

    <template v-slot:before>

      <q-tree
        v-if="modelValue.length"
        ref="tree"
        :nodes="modelValue"
        node-key="id"
        label-key="name"
        v-model:expanded="expandedNodes"
        v-model:selected="selectedNodeId"
        no-selection-unset
      >
        <template v-slot:default-header="prop">
          <div :key="prop.node.id" :draggable="!readonly" class="node-title" :class="{'drop-over': prop.node.id === dragOver}" @dragover.prevent @dragenter="dragOver = prop.node.id" @dragleave="dragOver = false" @drop="onDrop($event, prop.node)" @dragstart.stop="startDrag($event, prop.node)">{{ prop.node.name + (prop.node.title ? (`: ${prop.node.title}`) : '') }} <q-icon v-if="prop.node.icon" :name="prop.node.icon" size="12px" /></div>

          <q-space :key="prop.node.id" />

          <q-btn v-if="!readonly" :key="prop.node.id" icon="menu" flat size="sm">
            <q-menu>
              <q-list style="min-width: 100px">
                <q-item clickable v-close-popup @click="addNode(prop.node.parent_id)">
                  <q-item-section>Add node</q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="addNode(prop.node.id)" v-if="['object', 'db-record'].includes(prop.node.type)">
                  <q-item-section>Add property</q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="duplicateNode(prop.node)">
                  <q-item-section>Duplicate node</q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="deleteNode(prop.node)">
                  <q-item-section class="text-red">Delete node</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </template>
      </q-tree>

      <q-btn v-if="!readonly" class="q-ma-sm" @click="addNode(0)" label="Add variable" color="primary"></q-btn>

    </template>

    <template v-if="selectedNode" v-slot:after>
      <json-storage-node-value
        :mode="mode"
        :key="selectedNodeId"
        :module-id="moduleId"
        :readonly="readonly"
        :allow-injectable-vars="allowInjectableVars && !selectedChildNode"
        v-model="selectedNode"
      />
    </template>

  </q-splitter>

</template>

<style>
</style>

<script>
import {nanoid} from "nanoid";
import jp from 'jsonpath';
import get from 'lodash/get.js';
import set from 'lodash/set.js';
import JsonStorageNodeValue from "@/pages/workspace/storage/JsonStorageNodeValue.vue";

export default {
  name: 'JsonStorageTree',

  components: {JsonStorageNodeValue},

  emits: ['update:modelValue'],

  props: {
    modelValue: {
      required: true,
      type: Array,
      default: () => ([]),
    },
    mode: {
      default: "storage"
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    onlyArguments: {
      type: Boolean,
      default: false,
    },
    allowInjectableVars: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      splitterModel: 30,
      dragOver: false,
      editNode: false,
      isReady: false,
      expandedNodes: [],
      selectedNodeId: false
    };
  },

  /**
   * Init setting first
   */
  async created() {
    this.isReady = true
  },

  computed: {
    /**
     * Computes the module ID from the current route parameters.
     * @returns {string} The module ID.
     */
    moduleId() {
      return this.$route.params.module_id;
    },

    /**
     * Computes the path of the selected node.
     * @returns {string} The path of the selected node.
     */
    selectedNodePath() {
      return this.getNodePath(this.selectedNodeId);
    },

    /**
     * Determines if the selected node is a child node.
     * @returns {boolean} True if the selected node path includes 'children', otherwise false.
     */
    selectedChildNode() {
      return (this.selectedNodePath || '').includes('children');
    },

    /**
     * Computed property for the selected node.
     * Retrieves the node based on the selected node path.
     * Updates the node value in the model when set.
     */
    selectedNode: {
      get() {
        if (!this.selectedNodePath) {
          return null;
        }

        return get(this.modelValue, this.selectedNodePath);
      },
      set(val) {
        if (!this.selectedNodePath) {
          return;
        }

        set(this.modelValue, this.selectedNodePath, val);
      }
    },
  },

  methods: {
    /**
     * Retrieves the JSONPath of a node based on its ID.
     * @param {string} nodeId - The ID of the node.
     * @returns {Array} The JSONPath of the node.
     */
    getJpNodePath(nodeId) {
      return jp.paths(this.modelValue, `$..[?(@.id=='${nodeId}')]`)?.[0] || [];
    },

    /**
     * Retrieves the path of a node based on its ID.
     * @param {string} nodeId - The ID of the node.
     * @returns {string} The path of the node.
     */
    getNodePath(nodeId) {
      return this.getJpNodePath(nodeId)
        .filter((p) => p !== '$')
        .join('.');
    },

    /**
     * Start drag
     * @param event
     * @param node
     */
    startDrag(event, node) {
      event.dataTransfer.setData("text/plain", JSON.stringify(node));
      return true;
    },

    /**
     * On drop
     * @param event
     * @param node
     */
    async onDrop(event, node) {
      // Move node
      const source = JSON.parse(event.dataTransfer.getData("text/plain"));

      if (source?.id === node?.id) {
        this.dragOver = false;
        return false;
      }

      const targetPath = this.getJpNodePath(node.id).filter((p) => p !== '$').slice(0, -1).join('.');
      const sourcePath = this.getJpNodePath(source.id).filter((p) => p !== '$').slice(0, -1).join('.');

      if (sourcePath === targetPath) {
        this.dragOver = false;
        return false;
      }

      if (!targetPath) {
        this.deleteNodeBase(source);
        this.$emit('update:modelValue', [...this.modelValue, source]);
      } else {
        const parent = get(this.modelValue, targetPath);
        parent.push(source);
        this.$emit('update:modelValue', this.modelValue);

        this.deleteNodeBase(source);
      }

      this.dragOver = false;

      return true;
    },

    /**
     * Adds a new node to the tree.
     * Opens a dialog to prompt the user for the node name.
     *
     * @param {number} [parent_id=0] - The ID of the parent node. Defaults to 0 for root nodes.
     */
    async addNode(parent_id = 0) {
      this.$q.dialog({
        title: 'Add node',
        message: 'Enter node name',
        cancel: true,
        persistent: true,
        prompt: {
          model: '',
          type: 'text',
          ...(this.mode === 'tree-storage' ? {
            isValid: (val) => val !== 'id',
          } : {})
        }
      }).onOk(async (data) => {
        const node = {
          id: nanoid(10),
          title: '',
          type: 'string',
          db_table: 0,
          is_array: 0,
          update_state: 0,
          is_argument: 0,
          is_reference: 0,
          name: data,
          value: null,
          is_test_value: 0,
          is_localizable: 0,
          locale_alias: null,
          is_injectable: 0,
          tree_storage_node: 0,
          children: [],
        };

        if (parent_id) {
          const path = this.getNodePath(parent_id);
          const parent = get(this.modelValue, path);
          parent.children.push(node);
          this.$emit('update:modelValue', this.modelValue);
        } else {
          this.$emit('update:modelValue', [...this.modelValue, node]);
        }

        this.selectedNodeId = node.id;
      })
    },

    /**
     * Duplicate node
     * @param node
     * @return {Promise<void>}
     */
    async duplicateNode(node) {
      const parentPath = this.getJpNodePath(node.id).filter((p) => p !== '$').slice(0, -1).join('.');

      const generateNewNode = (n) => {
        return {
          ...n,
          id: nanoid(10),
          children: n.children.map(generateNewNode),
        };
      };

      const newNode = generateNewNode(node);

      if (!parentPath) {
        this.$emit('update:modelValue', [...this.modelValue, newNode]);
      } else {
        const parent = get(this.modelValue, parentPath);
        parent.push(newNode);
        this.$emit('update:modelValue', this.modelValue);
      }
    },

    /**
     * Deletes a node from the tree.
     *
     * @param {Object} node - The node to be deleted.
     */
    deleteNodeBase(node) {
      const parentPath = this.getJpNodePath(node.id).filter((p) => p !== '$').slice(0, -1).join('.');

      if (parentPath) {
        const parent = get(this.modelValue, parentPath);
        parent.splice(parent.findIndex((n) => n.id === node.id), 1);
        this.$emit('update:modelValue', this.modelValue);
      } else {
        this.$emit('update:modelValue', this.modelValue.filter((n) => n.id !== node.id));
      }
    },

    /**
     * Delete node
     * @param node
     * @return {Promise<void>}
     */
    deleteNode(node) {
      this.$q.dialog({
        title: 'Delete confirmation',
        message: `Are you sure want to delete ${node.name} ?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        this.deleteNodeBase(node);
      })
    },
  },
}
</script>

<style lang="scss">

.storage-tree {
  .node-title {
    width: 100%;
  }
  .drop-over {
    border-bottom: 2px solid #00f;
  }
}


</style>
