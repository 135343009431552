<template>
  <ui-prop-dynamic-string :block="block" title="Navigation link" name="navigationLink" untranslatable/>

  <template v-if="!isWeb">
    <ui-prop-dynamic-string :block="block" title="Title" name="windowTitle"/>
    <ui-prop-checkbox :block="block" title="Fullscreen" name="fullscreen" :true-value="1" :false-value="0" :default-value="0" />
    <ui-prop-checkbox :block="block" title="Open in a new browser window" name="openInBrowser" :true-value="1" :false-value="0" :default-value="0" />
  </template>
  <ui-prop-checkbox v-else :block="block" title="Open in dialog" name="openInDialog" :true-value="1" :false-value="0" :default-value="0" />
</template>

<script>

import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";
import UiPropCheckbox from '@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue';
import {propertiesMixins} from '@/components/DiagramDesigner/Editor/properties/propertiesMixins';

export default {
  name: "DeviceNavigatorPropsCmp",

  components: {UiPropDynamicString, UiPropCheckbox},

  mixins: [propertiesMixins],

  props: ['block'],

  computed: {
    isWeb() {
      return this.currentModule?.currentModule?.type === 'web';
    },
  },
}

</script>

