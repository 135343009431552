<template>
  <q-tabs v-model="tab">
    <q-tab name="props" label="Properties"/>
    <q-tab name="state" label="Page state"/>
    <q-tab name="db" label="DB"/>
  </q-tabs>

  <q-tab-panels v-model="tab">
    <q-tab-panel name="props">
      <diagram-invocation-list :module-id="module_id" :diagram-id="block.id" />
    </q-tab-panel>

    <q-tab-panel name="state">
      <storage-tree v-if="!isExternalSource" :app-id="product_id" :module-id="module_id" :block_id="storageId" :horizontal="true"/>
      <json-storage-tree v-else v-model="externalStorageModel" />
    </q-tab-panel>

    <q-tab-panel name="db">
      <ui-db-subscriptions-props :block="block" name="subscriptions"/>
    </q-tab-panel>
  </q-tab-panels>
</template>

<script>
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import StorageTree from "@/pages/workspace/storage/StorageTree.vue";
import UiDbSubscriptionsProps
  from "@/components/DiagramDesigner/Editor/properties/DbSubscription/UiDbSubscriptionsProps.vue";
import DiagramInvocationList from '@/components/DiagramDesigner/Editor/components/UI/Diagram/DiagramInvocationList.vue';
import JsonStorageTree from '@/pages/workspace/storage/JsonStorageTree.vue';
import {jsonStorageTreeMixin} from '@/components/DiagramDesigner/Editor/components/jsonStorageTreeMixin';

export default {
  mixins: [propertiesMixins, jsonStorageTreeMixin],
  name: "DiagramPropsCmp",
  props: ['block'],
  data: () => ({
    tab: "state"
  }),
  components: {JsonStorageTree, DiagramInvocationList, UiDbSubscriptionsProps, StorageTree},
  computed: {
    storageId() {
      return 'diagram-' + this.block.id
    }
  }
}

</script>

